import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import './BackgroundContainer.css';
import FeaturedHandymen from "./FeaturedHandyman";
import base_url from "../../base_url";
import axios from "axios";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const user = localStorage.getItem('handyman_ioiasod8921A_user');

export default function LandingPageComponent() {
  const [is_loggedin, setIsLoggedin] = useState(false);
  const [featured_handymen,setFeaturedHandymen] = useState([])

  const fetchFeatured = async()=>{
    axios.get(`${base_url}/apis/featured-handymen/users`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          
          'Content-Type': 'application/json',
        },
      })
    .then(response => {
      
      setFeaturedHandymen(response.data);
    })
    .catch(error => {
     console.log('Error fetching users:', error);
    });
}


  useEffect(() => {
   

    fetchFeatured()
  }, []);

  const navigate = useNavigate();

  return (
    <div>
    {/* <FloatingWhatsApp phoneNumber="+18142639626" accountName="81Handyman" chatMessage="Hello there! 🤝 How can we help you?" avatar={require('../../assets/logo-4-sticky.webp')}/> */}

    <div className="landing_container">
      <div className="landing_background-image">
        <div className="landing_background-overlay"></div>
      </div>
      <div className="landing_content container">
        <h1> Welcome to 81Handyman.com! </h1>
        <h5>Your Trusted Partner for Quality Handyman Services. No Job too Big or too Small.</h5>
        <h5>Please Register for your FREE QUOTE.</h5>

        <br />

        {is_loggedin === false ? (

          <div>

<Link 
    className="btn btn-warning" 
    to={'/pricings'} 
    style={{
        width: '100%',
        fontSize: 20,
        alignContent: 'center',
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }}
>
    CLICK to see National Average Handyman Job Pricing
</Link>            
          <div className="row mt-5">
            <div className="col-md-6 mb-2">
              <button
                className="login-button btn btn-primary"
                onClick={() => {
                  navigate('/login');
                }}
                style={{ width: '100%',height:50,justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:20 }}
              >
                Login
              </button>
            </div>
            <div className="col-md-6 mb-2">
              <button
                className="register-button btn btn-success"
                onClick={() => {
                  navigate('/register');
                }}
                style={{ width: '100%',height:50,justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:20 }}

              >
                Register
              </button>
            </div>
          </div>
          </div>

        ) : (
          <div className="col-md-6 offset-md-3 mb-2">
            <button
              className="register-button btn btn-success"
              onClick={() => {
                navigate('/home');
              }}
              style={{ width: '100%' }}
            >
              Go to Dashboard
            </button>
          </div>
        )}
      </div>



    </div>

    <FeaturedHandymen handymen={featured_handymen} /> 

    </div>

  );
}
