import axios from "axios";
import React,{useState,useEffect} from "react";
import base_url from "../../../base_url";
import { Spinner } from "react-activity";

export default function AdminMemberships(){
      // State for managing records
      const [data, setData] = useState([]);
      const [formData, setFormData] = useState({
          title: '',
          description: '',
          expiry_days: '',
          type: '',
          price: ''
      });

      const [isLoading,setisLoading] = useState(true)

      const validateForm = ()=>{
        if(formData.type == "normal" && formData.price.length<1 || formData.type == "normal" && formData.price == 0){
            alert("Price Must Be Greate Than $0")
            return false
        }
        return true
      }
  
      const handleSubmit = async(e) => {
          e.preventDefault();
          const validate = validateForm()

          if(validate){
            const user =await localStorage.getItem('handyman_ioiasod8921AAD')
            axios.post(`${base_url}/apis/membership/create-membership`,formData,{
            headers:{
                "x-api-key": process.env.REACT_APP_API_KEY,
                "authorization": user,
                'Content-Type': 'application/json', 
            }
            })
            .then(response => {
                // Update the records with the new data
                setData([...data, response.data]);
                setFormData({ title: '', description: '', expiry_days: '', type: 'trial', price: '' });
            })
            .catch(error => alert("Something Went Wrong"));

          }
      };
      

      const handleDelete = async(id)=>{
        const user =await localStorage.getItem('handyman_ioiasod8921AAD')

        axios.delete(`${base_url}/apis/membership/delete-membership?membership_id=${id}`,{
            headers:{
                "x-api-key": process.env.REACT_APP_API_KEY,
                "authorization": user,
                'Content-Type': 'application/json', 
            }
        })
        .then(() => {
            // Remove the deleted record from the state
            setData(data.filter(i => i._id !== id));
        })
        .catch(error => alert("Something Went Wrong"));
      }
      

      const fetchData =async ()=>{
        const user =await localStorage.getItem('handyman_ioiasod8921AAD')
        axios.get(`${base_url}/apis/membership/get-all-memberships-for-admin`,{
            headers:{
                "x-api-key": process.env.REACT_APP_API_KEY,
                "authorization": user,
                'Content-Type': 'application/json', 
            }
        })
        .then(res=>{
            if(res.status == 200){
                setData(res.data)
                setisLoading(false)
            }else{
                alert(res.data.message)
            }
        })
        .catch(err=>{
            alert("Something Went Wrong")
        })
      }


      useEffect(() => {
        fetchData()
      }, []);


      
  
      return (
          <div className="container mt-4" style={{overflowX:'scroll'}}>
              <h1>Membership Management</h1>


                {isLoading == false?<>
                
              {/* Form for Adding/Editing Records */}
              <form onSubmit={handleSubmit}>
                  <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input type="text" className="form-control" id="title" name="title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} required />
                  </div>
                  <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea className="form-control" id="description" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} rows="3" required></textarea>
                  </div>
                  <div className="form-group">
                      <label htmlFor="expiry_days">Expiry Days</label>
                      <input type="number" className="form-control" id="expiry_days" name="expiry_days" value={formData.expiry_days} onChange={(e) => setFormData({ ...formData, expiry_days: e.target.value })} required />
                  </div>
                  <div className="form-group">
                      <label htmlFor="type">Type</label>
                      <select className="form-control" id="type" name="type" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} required>
                      <option value="" selected>Please Select Type</option>
                        
                          <option value="trial">Trial</option>
                          <option value="normal">Normal</option>
                      </select>
                  </div>


                  {formData.type !="trial"?<div className="form-group">
                      <label htmlFor="price">Price</label>
                      <input type="number" className="form-control" id="price" name="price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} required />
                  </div>:null}
                  <button type="submit" className="btn btn-primary">Save</button>
              </form>
  
              <hr />
  
              {/* Table for Displaying Records */}
              <table className="table">
                  <thead>
                      <tr>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Expiry Days</th>
                          <th>Type</th>
                          <th>Price</th>
                          <th>Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      {data.map((i) => (
                          <tr key={i._id}>
                              <td>{i.title}</td>
                              <td>{i.description}</td>
                              <td>{i.expiry_days}</td>
                              <td>{i.type}</td>
                              <td>{i.price}</td>
                              <td>
                                 
                                  <button className="btn btn-danger" onClick={() => handleDelete(i._id)}>Delete</button>
                              </td>
                          </tr>
                      ))}
                  </tbody>
              </table>
              </>:<center><Spinner size={25} color="skyblue"/></center>}

          </div>
      );
  
}