// App.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import base_url from '../../../base_url';
import { Spinner } from 'react-activity';

function Parameters() {
  const [percentage, setPercentage] = useState(0);
  const [order_number,setOrderNumber] =  useState(0)
  const [isLoading,setisLoading] = useState(true)
  useEffect(() => {
    // Fetch default retailer percentage when component mounts
    fetchDefaultRetailerPercentage();
  }, []);

  const fetchDefaultRetailerPercentage = async () => {
    const user = await localStorage.getItem('handyman_ioiasod8921AAD');
  
       await axios.get(`${base_url}/api/defaultRetailerPercentage`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      })
        .then(response=>{
            
            setPercentage(response.data.percentage['$numberDecimal']);
            setOrderNumber(response.data.order_number)
            setisLoading(false)
        })
        .catch(err=>{
            alert("Something went wrong")
        })
  
  };

  const handlePercentageChange = async (e) => {

    const newPercentage = parseFloat(percentage);

    if(newPercentage>1){
        alert("Percentage must not be greater than 1")
        return false
    }
    setPercentage(newPercentage);

    const user = await localStorage.getItem('handyman_ioiasod8921AAD');
    
    await axios.put(`${base_url}/api/defaultRetailerPercentage`, { percentage: newPercentage }, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: user,
          'Content-Type': 'application/json',
        },
      })
    .then(res=>{
        alert("Updated Successfully");
    })
    .catch(er=>{
        alert("Something went wrong")
    })
   
  };


  if(isLoading == false){

  return (
    <div className="container mt-5">
      <h2>Default Retailer Percentage</h2>
      <div className="input-group mt-3">
        <input
          type="number"
          step="0.01"
          min="0"
          max="1"
          className="form-control"
          value={percentage}
         onChange={(val)=>{
            setPercentage(val.target.value)
            console.log(val.target.value)
         }}
        />
          
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" onClick={handlePercentageChange}>
            Update
          </button>
        </div>


      </div>

      <br />
       <h3>Current Defaul Retailer Percentages: {percentage*100}%</h3>
        <br />
        <hr />


      <h3>Current Order Number : {order_number}</h3>
      
    </div>
  );
}else{
    return <center><Spinner size={25} color='skyblue'/></center>
}

}

export default Parameters;
