import React, { useEffect, useState } from "react";
import base_url from "../../../base_url";
import axios from "axios";
import { Spinner } from "react-activity";

export default function FeaturedHandymen(){
    const [isLoading, setisLoading] = useState(true);
    const [featured_handymen, setFeaturedHandymen] = useState([]);

    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('');
    

    const fetchFeatured = async()=>{
     

        axios.get(`${base_url}/apis/featured-handymen/users`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              
              'Content-Type': 'application/json',
            },
          })
        .then(response => {
            console.log(response.data)
          setFeaturedHandymen(response.data);
        })
        .catch(error => {
         alert('Error fetching users:', error);
        });
    }


    const delete_featured = async(id)=>{
      const user = await localStorage.getItem('handyman_ioiasod8921AAD');
        

        axios.delete(`${base_url}/apis/featured-handymen/delete_featured_user?id=${id}`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              authorization: user,
              'Content-Type': 'application/json',
            },
          })
        .then(response => {
            alert("Delete Featured Handymen")
            fetchFeatured()
        })
        .catch(err => {
            if(err.response && err.response.data){
                alert(err.response.data.message)
               }else{
                alert("Something went wrong")
               }
        });
    }
    const fetchContractors = async()=>{
      const user = await localStorage.getItem('handyman_ioiasod8921AAD');

        // Fetch the list of users when the component mounts
        axios.get(`${base_url}/apis/featured-handymen/contractors`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              authorization: user,
              'Content-Type': 'application/json',
            },
          })
        .then(response => {
        setUsers(response.data);
        })
        .catch(error => {
            alert('Error fetching users:', error);
        });
    }
    useEffect(() => {
        fetchContractors()
        fetchFeatured()
        .then(()=>{
            setisLoading(false)
 
        })
        .catch(()=>{
            console.log("Error fetching")
        })

    }, []);
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      const user = await localStorage.getItem('handyman_ioiasod8921AAD');

      try {
        await axios.post(`${base_url}/apis/featured-handymen/add`, { userId },{
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                authorization: user,
                'Content-Type': 'application/json',
              }
        });

        alert('Featured handyman added successfully');
        fetchFeatured()
      } catch (err) {
        if(err.response && err.response.data){
            alert(err.response.data.message)
           }else{
            alert("Something went wrong")
           }
       
      }
    };
    

    if(isLoading == false){

    return <div className="container">
    <h2>Add Featured Handyman</h2>
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="userId" className="form-label">Select User</label>
        <select className="form-control" id="userId" value={userId} onChange={(e) => setUserId(e.target.value)}>
          <option value="">Select a user</option>
          {users?.map(user => (
            <option key={user._id} value={user._id}>{user.username}</option>
          ))}
        </select>
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
        
    <hr />
    <br />
    <div className="container">
      <h1>Featured Handymen</h1>
      {featured_handymen.length>0?<div className="row">
        {featured_handymen?.map(user => (
          <div className="col-md-4" key={user._id}>
            <div className="card mb-4">
            <img src={user.userInfo?.profile_picture?user.userInfo?.profile_picture:require('../../../assets/user-circle.png')} style={{width:210,height:200,alignSelf:'center',borderRadius:'100%',marginTop:10}}/>

              <div className="card-body">

                <h5 className="card-title" style={{textAlign:'center'}}>{user.userInfo?.username}</h5>
               
               <br />
               <button className="btn btn-danger" onClick={()=>delete_featured(user._id)}>Delete</button>
              </div>
            </div>
          </div>
        ))}
      </div>:<center><h2 style={{color:'red'}}>Currently Dont have any Featured</h2></center>}
    </div>
  </div>

}else{
    return <center>
        <br />
        <Spinner size={25} color="skyblue"/>
    </center>
}

}