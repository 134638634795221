import React, { useEffect, useState } from "react";



import Select from 'react-select';
import {  useParams } from "react-router-dom";
import axios from "axios";
import base_url from "../../../base_url";
import { Spinner } from "react-activity";

export default function EditOffer({socket,detectPageChange}){
  
    const [downPayment,setDownPayment] = useState(0)
    const [finalPayment,setFinalPayment] = useState(0) 
    const [message,setMessage] = useState('')
   
    const [OfferLoading,setOfferLoading] = useState(true)
    const [offerUpdatingLoading,setOfferUpdatingLoading] = useState(false)
    const params = useParams()
    
    const offer_id = params.offer_id
   

      const fetchOfferDetails = async()=>{
       const user = await localStorage.getItem('handyman_ioiasod8921A_user')

        axios.get(`${base_url}/apis/message/offer-details?offer_id=${offer_id}`,{
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "authorization": user,
              'Content-Type': 'application/json', 
            }})
        .then(res=>{
           
            setDownPayment(res.data.down_payment)
            setFinalPayment(res.data.final_payment)
            setMessage(res.data.message)
            setOfferLoading(false)
        })
        .catch(err=>{
            alert(err.message)
        })
      }


      const update_offer = async()=>{
        const user =await localStorage.getItem('handyman_ioiasod8921A_user');
  
        if(message.length < 1){
            alert("Message cannot be empty")
            return false
        }

        if(message.length>1000){
            alert("Message must not be more than 1000 characters")
            return false
        }
        if(downPayment < 5 || finalPayment<5){
            alert("Down payment and final payment must not be less than 5$")
            return false
        }


       

        setOfferUpdatingLoading(true)

        const newOffer = {
            message: message,
            downPayment: downPayment,
            finalPayment: finalPayment,
            
            offer_id:offer_id
        }
       

        axios.put(`${base_url}/apis/message/update_offer`,newOffer,{
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "authorization": user,
              'Content-Type': 'application/json', 
            }})
        .then(res=>{
          alert("Offer Updated Successfully")
          setOfferUpdatingLoading(false)
          window.history.back()
        })
        .catch(err=>{
          setOfferUpdatingLoading(false)

          if(err.response && err.response.data){
            alert(err.response.data.message)
           }else{
            alert("Something went wrong")
           }
        })

        


        
       
      }

   
      
      useEffect(()=>{
        detectPageChange(window.location.pathname)

        fetchOfferDetails()

        
      },[offer_id])
    return(
        <div style={{backgroundColor:'white',borderRadius:5,height:'100%',overflowY:'scroll'}}>
     
    
     
        <div className='p-2'>
    
        
           
            
    
            <div className='border p-2 mt-2' style={{borderRadius:5}}>
    
            <h5>Write your offer:</h5>
    
            <textarea className='form-control' placeholder='describe...' value={message} onChange={(val)=>setMessage(val.target.value)}></textarea>
            
    
            <div className='border p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{width:'45%'}}>
                    <b>Down payment: </b>
                    <input className='form-control' placeholder='Down Payment in usd' type='number' value={downPayment} onChange={(val)=>setDownPayment(val.target.value)}/>
                </div>
    
                <div style={{width:'45%'}}>
                    <b>Final payment: </b>
                    <input placeholder='Final Payment in usd' className='form-control' type='number' value={finalPayment} onChange={(val)=>setFinalPayment(val.target.value)}/>
                </div>
            </div>
    
            <button className='btn btn-success mt-2' style={{width:'100%'}} onClick={update_offer}>{offerUpdatingLoading?<Spinner color="#007aff" size={20}/>:'Update Offer'}</button>
       
            </div>
    
        </div>
    
    
        </div>
    )
}