import React, { useEffect, useState } from "react";
import base_url from "../../../base_url";
import './payment_methods.css'
import { Spinner } from "react-activity";
export default function PaymentMethods(){
    const [payment_methods,setPaymentMethods] = useState([])
    const [isLoading,setisLoading] = useState(false)
    const [error,setError] = useState(null)

    const user = localStorage.getItem('handyman_ioiasod8921A_user')
    const fetchPaymentMethods = ()=>{
        fetch(`${base_url}/apis/user/get-payment_methods`, {
            method: 'GET',
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "authorization": user,
              'Content-Type': 'application/json', 
            },
          })
     .then(res => res.json())
     .then(data=>{
        setPaymentMethods(data)
        setisLoading(false)
     })
     .catch(err=>{
        setisLoading(false)
        setError(err.message)
     })
    }


    const delete_payment_method=(id)=>{
        fetch(`${base_url}/apis/user/delete-payment_method?id=${id}`, {
            method: 'DELETE',
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
              "authorization": user,
              'Content-Type': 'application/json', 
            },
          })
          .then(async(res)=>{
            const data = await res.json();
            if(res.status == 200){
                fetchPaymentMethods()
            }else if(res.status == 404){
                alert(data.message)
            }
          })
          .catch(err=>{
            alert(err.message)
          })
    }
    useEffect(()=>{
        fetchPaymentMethods()
    },[])
    if(isLoading == false){
    if(error == null){

    return(
        <div>
        <div >
        <div className="border p-3">
            <h2 className=" mb-4">Payment Method</h2>
            {/* Add PayPal Modal Trigger */}
            {payment_methods.length<1?<a
            
            className="btn btn-success mb-3"
            
            href={`${base_url}/add_paypal_method?token=${user}&&secret_key=${process.env.REACT_APP_SECRET_KEY}`}
            >
            Add PayPal
            </a>:null}


            {/* Payment Methods List */}
            <ul className="list-group">
            {/* Sample Saved Card */}
              {payment_methods.map((data,index)=><li className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                {data.email}
                <span className="badge bg-primary">
                 {data.payment_method}</span>


                </div>
                <i class="fa fa-trash" style={{color:'red',fontSize:18,cursor:'pointer'}} onClick={()=>delete_payment_method(data._id)} aria-hidden="true"></i>
                
                 
            </li>)}
            
            </ul>
        </div>
        </div>

            
           
        </div>
    )
    }else{
        return <center className="mt-5"><h3>{error}</h3></center>
    }

    }else{
        return <center className="mt-5"><Spinner size={35} color="skyblue"/></center>
    }

}