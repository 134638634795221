import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart,faClock } from '@fortawesome/free-regular-svg-icons';
import '../../pages/main/home/JobsPage.css'; 

import base_url from '../../base_url';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import Select from 'react-select';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Spinner } from "react-activity";

const ContractorjobContainer = ({job,pageName,favorites,showDescription,handleToggleFavorite,socket,services,favorite_toggled})=>{
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const [openPanel, setOpenPanel] = useState(false);
const [is_sent_offer,SetisentOffer] = useState(false)
const [workNeeded, setWorkNeeded] = useState('');
const [downPayment,setDownPayment] = useState(0)
const [finalPayment,setFinalPayment] = useState(0) 
const [message,setMessage] = useState('')
const [sendOfferLoading,setsendOfferLoading] = useState(false)
const send_offer = async () => {
  try {
   
    const user = await localStorage.getItem('handyman_ioiasod8921A_user');

    if (message.length < 1) {
      alert("Message cannot be empty");
      return false;
    }

    if (message.length > 1000) {
      alert("Message must not be more than 1000 characters");
      return false;
    }

    if (downPayment < 5 || finalPayment < 5) {
      alert("Down payment and final payment must not be less than 5$");
      return false;
    }

    

    if((pageName == "home" && !job.added_by[0])  || (pageName != "home" && !job.addedByUser[0])){
      alert("Client has been removed or disable temporary")
      return false
    }
    setsendOfferLoading(true)
   
    setTimeout(async()=>{
      const offer = await socket.emit('new_message', {
        job_id: pageName == "home" ? job._id : job.job._id,
        sender: user,
        receiver: pageName == "home" ? job.added_by[0]._id : job.addedByUser[0]?.id,
        message: message,
        is_offer: true,
        down_payment: downPayment,
        final_payment: finalPayment,
        work_need_done: workNeeded,
      })
      

      if(offer._callbacks){
        setsendOfferLoading(false)
        SetisentOffer(true)
        setOpenPanel(false)
        toast.success('Offer Sent Successfully')
      }else{
      alert("Failed to send offer. Please try again to send offer");
      setsendOfferLoading(false)
      }
    },1200)
    
   
  } catch (err) {
   
    setsendOfferLoading(false)

    alert("Failed to send offer. Please try again to send offer");

  }
};

return(
    <div  className="col mb-4">
    <div className="card h-100">

    <div className="card-header">
   
    <img style={{width:30,height:30,borderRadius:50}}  src={pageName === "favorites" ? job.addedByUser[0]?.profile_picture?job.addedByUser[0]?.profile_picture:require('../../assets/user-circle.png') : job.added_by[0]?.profile_picture?job.added_by[0]?.profile_picture:require('../../assets/user-circle.png') } alt="User" className="user-image" />
   
    <b className="card-title">{' '}{ pageName === "favorites" ? job.addedByUser[0]?.username : job.added_by[0]?.username}</b>
    <b className="card-text float-right">Posted on: {pageName === "favorites" ? new Date(job.job.createdAt).toLocaleDateString() : new Date(job.createdAt).toDateString()}</b>

    
  </div>

      <div className="card-body">
       
          <h4 className="card-title">{pageName == "favorites"?job.job.name:job.name}</h4>
       
        <p className="card-text">
          <strong>Phone Number:</strong> {pageName == "favorites"?job.job.phone_number:job.phone_number}
          <br />
          <strong>Service:</strong> {pageName == "favorites"?job.job.type_of_work:job.type_of_work}
         
          <br />
          <strong>Zipcode:</strong> {pageName == "favorites"?job.job.zipcode:job.zipcode}
          
          <br />
          <strong>City:</strong> {pageName == "favorites"?job.job.location:job.location}

          <br />
          <strong>Address:</strong> {pageName == "favorites"?job.job.address:job.address}
        </p>
        <div className='border p-2'>
        <strong>description:</strong>
        {showDescription(job)}
        </div>

        <div className='border p-2 mt-2' style={{borderRadius:5}}>
        <b> <FontAwesomeIcon
          size={'lg'}
            icon={faClock}
            color={'blue'}
          /> APPOINTMENT OPTION  # 1</b>
          <br />
        <><b>Date: </b>{pageName == "favorites"?job.job.first_appointment_date:job.first_appointment_date}</>
        <br />

        <><b>Time: </b>{pageName == "favorites"?job.job.first_timeslot:job.first_timeslot}</>

        <hr />

        <b>  <FontAwesomeIcon
          size={'lg'}
            icon={faClock}
            color={'blue'}
          />APPOINTMENT OPTION # 2</b>
          <br />

        <><b>Date: </b> {pageName == "favorites"?job.job.second_appointment_date:job.second_appointment_date}</>
        <br />

        <><b>Time: </b> {pageName == "favorites"?job.job.second_timeslot:job.second_timeslot}</>

        </div>
        <br />

        {/* Toggle heart icon based on favorite status */}
        <button
          className="btn btn-link"
          disabled={favorite_toggled}
          onClick={() => handleToggleFavorite(pageName == "favorites"?job.job._id:job._id)}
        >
          <FontAwesomeIcon
          size={'lg'}
            icon={favorites.includes(pageName == "favorites"?job.job._id:job._id) ? solidHeart : regularHeart}
            color={favorites.includes(pageName == "favorites"?job.job._id:job._id) ? 'red' : 'gray'}
          />
        </button>


        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:10}}>

        

        <Link  to={`/chat/${pageName == "favorites"?job.addedByUser[0]?._id:job.added_by[0]?._id}`} className="btn btn-primary float-right mr-4">
          Chat With Client
        </Link>
       

        <button  disabled={is_sent_offer || job.sent_offers.length>0} onClick={() =>{
          setWorkNeeded(pageName != "favorites" ?job?.type_of_work:job?.job.type_of_work)
          setOpenPanel(true)
          console.log(pageName != "favorites" ?job.type_of_work:job.job.type_of_work)
        }}  className="btn btn-success float-right mr-4">
        {is_sent_offer || job.sent_offers.length>0?'Offer Sent':'Send Offer'} 
        </button>

        </div>
      </div>
    </div>


  
    <SlidingPanel
    type={'right'}
    isOpen={openPanel}
   
    backdropClicked={()=>setOpenPanel(false)}
    size={isMobile? 85:60}
  >
    <div style={{backgroundColor:'white',borderRadius:5,height:'100%',overflowY:'scroll'}}>
     
    <div className="back-button-container p-3" style={{borderRadius:5}}>
    <div className="back-arrow" onClick={()=>setOpenPanel(false)}>&#9665;</div>
    <div className="button-text" style={{cursor:'pointer'}} onClick={()=>setOpenPanel(false)}>Go Back</div>
  </div>

    
  <div className="card-header">
    <img style={{width:30,height:30,borderRadius:50}}  src={pageName === "favorites" ? job.addedByUser[0]?.profile_picture?job.addedByUser[0]?.profile_picture:require('../../assets/user-circle.png') : job.added_by[0]?.profile_picture?job.added_by[0]?.profile_picture:require('../../assets/user-circle.png') } alt="User" className="user-image" />
   
    <b className="card-title">{pageName === "favorites" ? job.addedByUser[0]?.username : job.added_by[0]?.username}</b>
    <small className="card-text float-right">Posted on: {pageName === "favorites" ? new Date(job.job.createdAt).toLocaleDateString() : new Date(job.createdAt).toDateString()}</small>
     </div>
    
    <div className='p-2'>

        <div className='border p-2' style={{borderRadius:5}}>
        <h4>{pageName != "favorites" ?job.name:job?.job.name}</h4>

        </div>

        <div className='border p-2 mt-2' style={{borderRadius:5}}>
        <strong>Description:</strong>
        {showDescription(job)}
        </div>


        <div className='border p-2 mt-2' style={{borderRadius:5}}>
        <b> <FontAwesomeIcon
          size={'lg'}
            icon={faClock}
            color={'blue'}
          /> APPOINTMENT OPTION # 1</b>
          <br />
        <><b>Date: </b>{pageName == "favorites"?job.job.first_appointment_date:job.first_appointment_date}</>
        <br />

        <><b>Time: </b>{pageName == "favorites"?job.job.first_timeslot:job.first_timeslot}</>

        <hr />

        <b>  <FontAwesomeIcon
          size={'lg'}
            icon={faClock}
            color={'blue'}
          /> APPOINTMENT OPTION # 2</b>
          <br />

        <><b>Date: </b> {pageName == "favorites"?job.job.second_appointment_date:job.second_appointment_date}</>
        <br />

        <><b>Time: </b> {pageName == "favorites"?job.job.second_timeslot:job.second_timeslot}</>

        </div>
        

        <div className='border p-2 mt-2' style={{borderRadius:5}}>

        <h5>Write your offer:</h5>

        <textarea className='form-control' placeholder='describe...' onChange={(val)=>setMessage(val.target.value)}></textarea>
       

        <div className='border p-2' style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{width:'45%'}}>
                <b>Down payment: </b>
                <input className='form-control' placeholder='Down Payment in usd' type='number'  min="1"
                  step="1" onChange={(val)=>setDownPayment(val.target.value)}/>
            </div>

            <div style={{width:'45%'}}>
                <b>Final payment: </b>
                <input placeholder='Final Payment in usd'  min="1"
                  step="1" className='form-control' type='number' onChange={(val)=>setFinalPayment(val.target.value)}/>
            </div>
        </div>
        <br />
        {sendOfferLoading == false?<button disabled={is_sent_offer || job.sent_offers.length>0} onClick={send_offer} className='btn btn-success mt-2' style={{width:'100%'}}>{is_sent_offer || job.sent_offers.length>0?'Offer Sent':'Create offer'}</button>:<button disabled={true}  className='btn btn-success mt-2' style={{width:'100%'}}>{<Spinner color='skyblue' size={18}/>}</button>}
        <br />
        <br />
        <br />

        </div>

    </div>


    </div>
  </SlidingPanel>
  

    
  </div>



)
}
 

export default ContractorjobContainer