import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import './JobsPage.css'; // Import the CSS file
import axios from 'axios';
import base_url from '../../../base_url';

import { Spinner } from 'react-activity';
import ClientjobContainer from '../../../components/main/ClientjobContainer';



const user = localStorage.getItem('handyman_ioiasod8921A_user')
const ClientJobsPage = ({jobs,isLoading,getJobs,pageName,user}) => {
  const [favorites, setFavorites] = useState([]);
  const [deletionLoading,setDeletionLoading] = useState(false)
  const maxLength = 150; // Set your preferred maximum length
  const [isTruncated, setIsTruncated] = useState(true);
  const [favorite_toggled,setFavoriteToggled] = useState(false)
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };


  const showDescription =(job)=>{
    
    const description = pageName === 'favorites' ? job.job.description : job.description;
    const displayDescription = isTruncated ? description?.slice(0, maxLength) : description;
    return <p>{displayDescription} {description&&description.length>150&&<b style={{color:"#007aff",cursor:'pointer'}} onClick={toggleTruncate}>{isTruncated?' Readmore':' Read Less'}</b>}</p>
  }

  const delete_job = async(job_id)=>{
    const user =await localStorage.getItem('handyman_ioiasod8921A_user')

    setDeletionLoading(true)
    await axios.delete(`${base_url}/apis/job/delete_job?job_id=${job_id}`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      }
    })
    .then(res=>{
      if(res.status == 200){
        getJobs()
        alert("Job Delete Successfully")
      }else{
        alert("Something Went Wrong")
      }
    setDeletionLoading(false)

    })
    .catch(err=>{
      alert("Something Went Wrong")
      setDeletionLoading(false)

    })
  }

 
  const handleToggleFavorite = async (jobId) => {
  const user =await localStorage.getItem('handyman_ioiasod8921A_user')
  setFavoriteToggled(true)
    try {
      // Check if the job is already in favorites
      const isFavorite = favorites.some((favorite) => favorite === jobId);
     
      if (isFavorite) {
        // If it's already a favorite, unfavorite it
        await axios.delete(`${base_url}/apis/job/unfavorite?jobId=${jobId}`,{
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json', // Set the content type
          },
        });
        setFavorites((prevFavorites) => prevFavorites.filter((favorite) => favorite !== jobId));
        if(pageName == "favorites"){
          getJobs()
        }
      } else {
        // If it's not a favorite, favorite it
        await axios.post(`${base_url}/apis/job/favorite`, { jobId },{
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json', // Set the content type
          },
        });
        setFavorites((prevFavorites) => [...prevFavorites, jobId]);
      }

      setFavoriteToggled(false)
    } catch (error) {
      alert("Something Went Wrong")
      setFavoriteToggled(false)

      console.error('Error toggling favorite:', error.message);
    }
  };

  const gatherFavorites = ()=>{
    if(pageName == "home"){
      jobs?.filter(i=>{
        if(i.isFavorite){
          setFavorites((prevFavorites) => [...prevFavorites, i._id]);
  
        }
      })
    }else{
      jobs?.filter(i=>{
        

          setFavorites((prevFavorites) => [...prevFavorites, i.job._id]);
  
        
      })
    }
  }
  useEffect(()=>{
   
      gatherFavorites()
  
  },[])


  

  return (
    <div className="container mt-5" >
      

      <div className='border p-4' style={{borderRadius:5}}>
      <button className='btn btn-primary float-right' onClick={() => window.location.reload()}>
      <i className="fa fa-refresh" aria-hidden="true" style={{fontSize:15}}/> Refresh
    </button>
{pageName == "favorites" ? 
  <h3 className="mb-4">Your Favorite Jobs</h3> :
  <div>
    <h4 style={{borderRadius:5}}>Welcome Back 😊 <b>{user?.username}</b></h4>
    
  </div>
}

<br />

<div className="row">
  <div className='col-md-6 mb-3'>
    <div className='border p-3' style={{borderRadius:5}}>
      <h6><i className="fa fa-tasks" aria-hidden="true"></i> Request quote for your job</h6>
      <Link to="/post-job" className="btn btn-success mt-3" style={{width:'100%'}}>
        Click here for your FREE quote
      </Link>
    </div>
  </div>

  <div className='col-md-6 mb-3'>
    <div className='border p-3' style={{borderRadius:5}}>
      <h6><i className="fa fa-envelope-open" aria-hidden="true"></i> Resume your communication with Handymen</h6>
      <Link to="/chat/0" className="btn btn-success mt-3" style={{width:'100%'}}>
        Messages
      </Link>
    </div>
  </div>
</div>
</div>


    <br />
      {/* {pageName == "home"?<div className="mt-4 mb-3 ">
        <Link to="/post-job" className="btn btn-primary" style={{width:220}}>
          Post New Job+
        </Link>
      </div>:null} */}


      {isLoading === false ? (
        <div className=" row-cols-md-2 row-cols-lg-3 g-4">
          
          {jobs.length > 0 ? (
            jobs.map((job) => (
              <ClientjobContainer key={job._id} job={job} favorite_toggled={favorite_toggled} pageName={pageName} showDescription={showDescription} handleToggleFavorite={handleToggleFavorite} delete_job={delete_job} deletionLoading={deletionLoading} favorites={favorites}/>
            ))
          ) : (
            <center className="mt-5">
              <h1>No Jobs Found 🚫</h1>
            </center>
          )}
        </div>
      ) : null}
      
      
    </div>
  );
};

export default ClientJobsPage;


