import { useEffect, useState } from "react";
import {
    redirect,
    Navigate
  } from "react-router-dom";
import base_url from "../base_url";
import SomethingWentWrong from "../pages/something_wentwrong";
const MemberShipRequired = ({children})=>{
    const [HasMembership,setHasMembership] = useState(true)
    const [isError,setError] = useState(false)
    const [is_loading,setLoading] = useState(true)
    const user = localStorage.getItem('handyman_ioiasod8921A_user')

    const CheckMembership = ()=>{
   
        fetch(base_url+'/apis/user_membership/user-membership-details',{
            method:'GET',
           
            headers:{
                "x-api-key":process.env.REACT_APP_API_KEY,
                "authorization":user
            }
          })
          .then(res=>res.json())
          .then(response=>{
           
           if(response.found == false){
            
            setHasMembership(false)
           }else{
            setHasMembership(true)

           }

           setLoading(false)
          })
          .catch(error=>{
          setError(true)
           setLoading(false)


          })
    }

    useEffect(()=>{
        CheckMembership()
    },[])

    if(isError){
        return  <SomethingWentWrong />
    }
    
    if(!HasMembership && isError == false){
        return <Navigate to={'/memberships'} />
    }else if(HasMembership && is_loading == false && isError == false){
        return children
    }
}

export default MemberShipRequired