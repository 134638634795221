import {
    redirect,
    Navigate
  } from "react-router-dom";
const AdminAuthRequired = ({children})=>{
    const user = localStorage.getItem('handyman_ioiasod8921AAD')
    if(!user){
        return <Navigate to={'/login'} />
    }else{
        return children
    }
}

export default AdminAuthRequired