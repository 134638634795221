import React, { useState, useEffect } from 'react';
import './style.css'; // Custom CSS file for styling
import { Link } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../../base_url';
import { Spinner } from 'react-activity';

const Orders = ({ user,detectPageChange }) => {
  const [orders, setOrders] = useState([]);
  const [orderFinalPayment, setOrderFinalPayment] = useState(0);
  const [orderUpdatingLoading,setOrderUpdatingLoading] = useState(false)
  const [filter, setFilter] = useState("");
  const [updatingOrderId, setUpdatingOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    detectPageChange(window.location.pathname)
   
    fetchOrders();
  }, []);
 
  const fetchOrders = async () => {
    try {
      const userToken = await localStorage.getItem('handyman_ioiasod8921A_user');
      const response = await axios.get(`${base_url}/apis/order/orders`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": userToken,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        setOrders(response.data);
        setIsLoading(false);
      } else {
        alert("Something Went Wrong");
      }
    } catch (error) {
      alert("Something Went Wrong");
    }
  };

  const handleEditOrder = (id, defaultFinalPayment) => {
    if (updatingOrderId === id) {
      setUpdatingOrderId(null);
      setOrderFinalPayment(0);
    } else {
      setUpdatingOrderId(id);
      setOrderFinalPayment(defaultFinalPayment);
    }
  };

  const handUpdateOrder = async()=>{

    if(orderFinalPayment <5){
      alert("Final Payment Should Be Greater Than 5")
      return false
    }
    setOrderUpdatingLoading(true)
    const userToken = await localStorage.getItem('handyman_ioiasod8921A_user');
    const formData = new FormData();
    formData.append('order_id', updatingOrderId);
    formData.append('final_payment', orderFinalPayment);
    axios.put(`${base_url}/apis/order/updated_order_final_payment`, formData, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": userToken,
        'Content-Type':'multipart/form-data'
      }
    }).then(response => {
      
        const updatedOrders = orders.map(order => {
          if (order._id == updatingOrderId) {
            return { ...order, final_payment: orderFinalPayment };
          } else {
            return order;
          }
        });
        setOrders(updatedOrders);
        alert("Order Updated Successfully");
        setOrderUpdatingLoading(false)
        setUpdatingOrderId(null)
        setOrderFinalPayment(0)



      
    }).catch(error => {
      if(error && error.response.data.message){
        alert(error.response.data.message)
      }else{
        alert("Failed to update order.Please try again");

      }
      setOrderUpdatingLoading(false)
      setUpdatingOrderId(null)
      setOrderFinalPayment(0)
    
    })
  }



  useEffect(()=>{
    fetchOrders()
  },[])

  if(isLoading == false){

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Manage Orders</h1>
      <select className='form-control' onChange={(event) => setFilter(event.target.value)}>
        <option value={''}>All</option>
        <option value={'pending'}>Progress</option>
        <option value={'completed'}>Completed</option>
        <option value={'cancelled'}>Cancelled</option>
      </select>
      <div className="row row-cols-2 row-cols-md-2 g-2 mt-3">
        {isLoading ? (
          <center>
            <Spinner size={25} color='skyblue'/>
          </center>
        ) : (
          orders.filter(order => order.status === filter || filter === "").map((order, index) => (
            <div key={index} className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
              <div className={`card border-primary`}>
                <div className="card-body">
                  <small style={{background:'skyblue',padding:5,borderRadius:5,color:'white',fontSize:15}}>
                    {order.status === "pending" ? 'progress' : order.status}
                  </small>
                  <br />
                  <br />
                  <h4>Order No:  {order.order_number}</h4>


                  <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Service <b>{order.work_need_done}</b> </div>


                  <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Down Payment <b>${order.down_payment}</b></div>


                  <div  style={{flexDirection:'row',display:'flex',flexWrap:'wrap',justifyContent:'space-between',alignItems:'center'}} className="card-text  border p-1 ">


                    <p style={updatingOrderId == order._id?{width:'30%'}:{marginTop:10,marginLeft:10}}>Final Payment: </p> {
                      updatingOrderId !== order._id ? (
                        <div style={{flexDirection:'row',display:'flex'}}>
                          <b>${order.final_payment}  </b>
                          {user.user_type == "client" && order.status == "pending" && orderUpdatingLoading == false?<i
                            onClick={() => handleEditOrder(order._id, order.final_payment)}
                            style={{ cursor:'pointer ',fontSize:20 }}
                            className='fa fa-edit mt-1 ml-2 text-primary'
                          ></i>:null}
                        </div>
                      ) : (
                        <div style={{width:'100%'}} >
                          <input
                            className='form-control'
                            type='number'
                            placeholder='final payment amount'
                            value={orderFinalPayment}
                            style={{width:'100%'}}
                            onChange={(event) => setOrderFinalPayment(event.target.value)}
                          />
                          <div className='row' style={{ justifyContent: 'space-between', padding: 15 }}>
                            <button className='btn btn-primary float-left' disabled={orderUpdatingLoading} onClick={handUpdateOrder} style={{ width: '50%' }}>{orderUpdatingLoading == false?'Update':<Spinner color='skyblue' style={{alignSelf:'center'}} size={15}/>}</button>
                            <button className='btn btn-danger ml-2 float-right' style={{ width: '20%' }} onClick={() => setUpdatingOrderId(null)}>Close</button>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Address: <b>{order.property_address}</b></div>

                  <div className="card-text border p-3" style={{flexDirection:'row',display:'flex', justifyContent:'space-between'}}>Start Date: <b>{new Date(order.createdAt).toUTCString()}</b></div>

                    <br />
                  <div className="border p-2" style={{color:order.client[0]?'black':'red'}}>
                    <p style={{fontSize:13,color:'white',backgroundColor:'#007aff',padding:3,borderRadius:5,position:'relative'}} className='float-right mr-2'>Client</p>
                    {order.client[0] ? <img src={order.client[0]?.profile_picture ? order.client[0].profile_picture : require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/> : null} {order.client[0] ? order.client[0]?.username : 'deleted by admin'}
                  </div>
                  <div className="border p-2" style={{color:order.contractor[0]?'black':'red'}}>
                    <p style={{fontSize:13,color:'white',backgroundColor:'#007aff',padding:3,borderRadius:5,position:'relative'}} className='float-right mr-2'>Handyman</p>
                    <a href={`/contractor_profile/${order.contractor[0]?._id}`} style={order.contractor[0]?{}:{pointerEvents:'none',cursor:'default'}}>{order.contractor[0]?<img src={order.contractor[0]?.profile_picture?order.contractor[0].profile_picture:require('../../../assets/user-circle.png')} style={{width:30,height:30,borderRadius:'100%'}}/>:null}</a> {order.contractor[0]?<a href={`/contractor_profile/${order.contractor[0]?._id}`} >{order.contractor[0]?.username}</a>:'deleted by admin'}
                  </div>
                  <br />


                  <a href={`/single_order/${order._id}`} className='btn btn-primary' style={{width:'100%'}}>View</a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
};
export default Orders;
