import React, { useState, useEffect } from 'react';
import base_url from '../../../base_url';

const AdminTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
    const [transferedByFilter, setTransferedByFilter] = useState('');
    const [transferedToFilter, setTransferedToFilter] = useState('');
    const [orderNumberFilter, setOrderNumberFilter] = useState('');

    const fetchTransactions = async () => {
        const user = await localStorage.getItem('handyman_ioiasod8921AAD');
        try {
            const query = new URLSearchParams({
                page: currentPage,
                limit: 50,
                transfered_by: transferedByFilter,
                transfered_to: transferedToFilter,
                order_number: orderNumberFilter
            }).toString();
            const response = await fetch(`${base_url}/apis/transaction/transactions?${query}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    authorization: user,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch transactions');
            }
            const { transactions, totalPages, totalTransactionsCount } = await response.json();
            setTransactions(transactions);
            setTotalPages(totalPages);
            setTotalTransactionsCount(totalTransactionsCount);
        } catch (error) {
            console.error(error);
            alert('Failed to fetch transactions. Please try again');
        }
    };


    const generateCsv = () => {
        if (transactions.length < 1) {
            alert("You don't have any records to generate CSV");
            return false;
        }
        
        const selectedColumns =Object.keys(transactions[0]);
     
        const csvContent = 'data:text/csv;charset=utf-8,' +
            selectedColumns.join(',') + '\n' +
            transactions.map(row => {
                return selectedColumns.map(col => {
                    const keys = col.split('.');
                    let value = row;
                    for (let key of keys) {
                        // console.log(typeof value[key]," ",key," ",value[key])
                        if((typeof value == "object" && key == "transferedBy" && value[key][0]) || (typeof value == "object" && key == "transferedTo" && value[key][0]) ){
                            // console.log("here",)
                            value = value[key][0].username
                        }else if((typeof value == "object" && key == "our_percentage" )){
                           
                          
                            value = JSON.stringify(value[key])
                        }else{
                            value = value[key];

                        }
                    }
                    return value;
                }).join(',');
            }).join('\n');
        console.log(csvContent)
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'transactions.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    


    useEffect(() => {
        fetchTransactions();
    }, [currentPage, transferedByFilter, transferedToFilter, orderNumberFilter]);

    const remainingPages = totalPages - currentPage;

    return (
        <div className="container">
            <h1 className="my-4">Transactions</h1>
            <button onClick={generateCsv} className='btn btn-primary float-right'>Download CSV</button>
            <br />
            <p>Total Transactions: {totalTransactionsCount}</p>
            <div className="filters mb-4">
                <input
                    type="text"
                    placeholder="Filter by Transfered By"
                    className='form-control'
                    value={transferedByFilter}
                    onChange={(e) => setTransferedByFilter(e.target.value)}
                />
                <br />
                <input
                    type="text"
                    placeholder="Filter by Transfered To"
                    className='form-control'
                    value={transferedToFilter}
                    onChange={(e) => setTransferedToFilter(e.target.value)}
                />
                <br />

                <input
                    type="number"
                    placeholder="Filter by Order Number"
                    className='form-control'

                    value={orderNumberFilter}
                    onChange={(e) => setOrderNumberFilter(e.target.value)}
                />
                <br />

                {/* <button onClick={() => fetchTransactions()} className="btn btn-primary " style={{width:'100%'}}>Filter</button> */}
            </div>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Transfered By</th>
                        <th>Transfered To</th>
                        <th>Total Amount</th>
                        <th>Handyman Amount</th>
                        <th>Our Percentage</th>
                        <th>Our Amount</th>
                        <th>Purpose</th>
                        <th>Order no</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr key={transaction._id}>
                            <td>{index + 1}</td>
                            <td>{transaction.transferedBy[0] ? transaction.transferedBy[0].username : 'deleted by admin'}</td>
                            <td>{transaction.transferedTo[0] ? transaction.transferedTo[0].username : 'deleted by admin'}</td>
                            <td>{transaction.whole_amount}</td>
                            <td>{transaction.handyman_amount}</td>
                            <td>{transaction.our_percentage ? transaction.our_percentage['$numberDecimal'] * 100 + '%' : '#'}</td>
                            <td>{transaction.our_amount}</td>
                            <td>{transaction.payment_for}</td>
                            <td>{transaction.order_number ? transaction.order_number : '#'}</td>
                            <td>{transaction.createdAt}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} className="btn btn-secondary">Previous Page</button>
                <span className="ml-3 mr-3">{currentPage}/{totalPages}</span>
                <button disabled={remainingPages <= 0} onClick={() => setCurrentPage(currentPage + 1)} className="btn btn-primary">Next Page</button>
            </div>
        </div>
    );
};

export default AdminTransactions;
