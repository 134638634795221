import React, { useState } from 'react';
import axios from 'axios';
import base_url from '../../../base_url';

const PricingItem = ({ pricing, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newPrice, setNewPrice] = useState(pricing.price);
  const [newTo, setNewTo] = useState(pricing.to);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdate = async () => {
    try {
      const user = await localStorage.getItem('handyman_ioiasod8921AAD');
      await axios.put(
        `${base_url}/apis/pricing/${pricing._id}`,
        { price: newPrice, to: newTo },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            authorization: user,
            'Content-Type': 'application/json',
          },
        }
      );
      setIsEditing(false);
      
    } catch (error) {
      alert('Failed to update pricing. Please try again');
    }
  };

  return (
    <tr>
      <td>{pricing.service}</td>
      <td>
        {isEditing ? (
          <>
            <input
              type="number"
              className='form-control'

              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
            />
            -
            <input
              type="number"
              className='form-control'
              value={newTo}
              onChange={(e) => setNewTo(e.target.value)}
            />
          </>
        ) : (
          `$${newPrice} - $${newTo}`
        )}
      </td>
      <td>
        {isEditing ? (
          <button className="btn btn-success" onClick={handleUpdate}>Update</button>
        ) : (
          <>
            <button className="btn btn-primary" onClick={handleEdit}>Edit</button>
            <button className="btn btn-danger" onClick={onDelete}>Delete</button>
          </>
        )}
      </td>
    </tr>
  );
};

export default PricingItem;
