import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-activity';
import axios from 'axios';

import { Rating } from 'react-simple-star-rating';
import base_url from '../../../base_url';


const SingleReview = ({ name, rating, comment, profilePic }) => (
  <div className="card mb-4">
    <div className="card-body">
      <div className="d-flex align-items-center">
        <img
          src={profilePic?`${base_url}/uploads/${profilePic}`:require('../../../assets/user-circle.png')}
          alt={`Profile of ${name}`}
          className="rounded-circle mr-3"
          style={{width:80,height:80}}
        />
        <div>
          <h5 className="card-title">{name?name:''}</h5>
          <div className="d-flex align-items-center">
            
            <div className="rating">
              <Rating initialValue={rating} readonly/>
            </div>
          </div>
        </div>
      </div>
      <p className="card-text mt-3">{comment}</p>
    </div>
  </div>
);

const AccountReviews = ({detectPageChange}) => {
 const [reviews,setReviews] = useState([])
 const [isLoading,setisLoading] = useState(true)
 const getReviews = async()=>{
    const user =await localStorage.getItem('handyman_ioiasod8921A_user')
    await axios.get(`${base_url}/apis/auth/profile/ratings`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', 
        },
    })
    .then(res=>{
        
        setReviews(res.data)
        setisLoading(false)
    })
    .catch(err=>{
        alert("Something Went Wrong")
    })

 }

 useEffect(()=>{
    detectPageChange(window.location.pathname)
    getReviews()

 },[])



  if(!isLoading){
    return <div className="container">
    <h3 className="my-4">My Reviews ({ reviews?.length}): </h3>
    {reviews.map((review) => (
       <SingleReview
        key={review._id}
        name={review.client[0].username}
        rating={review.rating}
        comment={review.comment}
        profilePic={review.client[0].profile_picture}
      />
    ))}
  </div>
  }else{
    return <center>
        <br />
        <br />

        <Spinner color='skyblue' size={25}/>
    </center>
  }
  
}

export default AccountReviews;
