import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import base_url from '../../../base_url';


import { Spinner } from "react-activity";
import "react-activity/dist/library.css";


export default function Login({detectPageChange}){
  const [email,setEmail] = useState('')
  const [password,setPassword] = useState('')
  const [is_loading,setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const login = ()=>{
    if(is_loading){
      return false
    }else{
      setIsLoading(true)
    }
    const formData = new FormData()
    formData.append("email",email)
    formData.append("password",password)
    fetch(base_url+'/apis/auth/login',{
      method:'POST',
      body:formData,
      headers:{
          "x-api-key":process.env.REACT_APP_API_KEY
      }
    })
    .then(response => response.json())
    .then(async(json)=>{
      setIsLoading(false)
      if(json.is_logged_in){
        if(json.is_admin == false){
          await localStorage.setItem('handyman_ioiasod8921A_user',json.token)
          
          
          window.location = "/home"

        }else{
        
          await localStorage.setItem('handyman_ioiasod8921AAD',json.token)
         
          window.location = "/admin"
        }
      
      }else{
        alert(json.message)
      }
    })
    .catch(error => {
      setIsLoading(false)

      // Handle error
      
      alert("Something Went Wrong")
      console.error(error);
    });
  }

    useEffect(()=>{
      detectPageChange(window.location.pathname)
    },[])
 
    return(
        <>
  {/*Start form area*/}
  <section className="contact-info-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="contact-form">
            <div className="row">
              <div className="col-xl-12">
                <div className="sec-title-style1 float-left">
                  <div className="title">Login Form</div>
                  <div className="text">
                    <div className="decor-left">
                      <span />
                    </div>
                    <p>Login Form</p>
                  
                  </div>
                </div>
               
              </div>
            </div>
            <div className="inner-box">
              <form
                id="contact-form"
                name="contact_form"
                className="default-form"
               
                
              >
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    
                    
                      <div className="col-xl-12">
                        <div className="input-box">
                          <input
                            type="email"
                            onChange={(val)=>setEmail(val.target.value)}
                            name="form_email"
                            defaultValue=""
                            placeholder="Email"
                            required=""
                          />

                        </div>


                        
                        <div className="input-box" style={{ position: 'relative' }}>
                        <input
                          onChange={(val) => setPassword(val.target.value)}
                          type={showPassword ? "text" : "password"}
                          name="form_email"
                          value={password}
                          placeholder="Password"
                          required
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                          }}
                        >
                          <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </span>
                      </div>
                     



                    
                        <div className='btn btn-primary' onClick={login} data-loading-text="Please wait...">
                        {is_loading?<Spinner size={18} color='skyblue'/>:'Login'}
                       
                      </div>
                    

                        <br />
                        <center>


                        <p>Want An 81Handyman.com Account?<Link to={'/register'}>Please Click Here!</Link></p>
                        <br />

                        <p><Link to={'/reset/verify'}>Reset Password</Link></p>

                        </center>

                    </div>
                   
                 
                  </div>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*End form area*/}
</>

    )
}