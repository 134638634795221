import React from 'react'
import { Link } from 'react-router-dom'

 export default  function AdminHeader({childern}){
   

  const is_loggedin = localStorage.getItem('handyman_ioiasod8921AAD')
  const logout = async()=>{
   await localStorage.removeItem('handyman_ioiasod8921AAD')
   window.location ="/login"
   }

      if(is_loggedin){
        return(
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="/admin">
            Admin Panel
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <Link className="nav-link" to="/admin">
                  Home
                </Link>
              </li>


              <li className="nav-item ">
                <Link className="nav-link" to="/admin/users/">
                  All users
                </Link>
              </li>
              

              <li className="nav-item ">
                <Link className="nav-link" to="/admin/jobs">
                  Jobs
                </Link>
              </li>


              <li className="nav-item ">
                <Link className="nav-link" to="/admin/pricings">
                  Pricings
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/admin/services">
                  Services
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/admin/cities">
                  Cities
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/admin/orders">
                  Orders
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link" to="/admin/transactions">
                  Transactions
                </Link>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link" to="/admin/featured_handymen">
                  Featured Handymen
                </Link>
              </li>

             
              <li className="nav-item">
                <Link className="nav-link" to="/admin/memberships">
                  Memberships
                </Link>
              </li>



              <li className="nav-item">
                <button className="nav-link btn btn-danger"onClick={()=>{
                  logout()
                }}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </nav>
        )
      }else{
        return childern
      }
   
      
    }