import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import axios from 'axios'
import base_url from '../../../base_url'
import {  useParams } from 'react-router-dom';
import TimeSlotManager from '../account/timeslots';



const EditJob = ({detectPageChange}) => {
  const params = useParams()
 
  const [name, setName] = useState('');
  const [address,setAddress] = useState('');
 
  const [workNeeded, setWorkNeeded] = useState('');
  const [city,setCity] = useState('');
  const [defaultWorkNeededValue,setDefaultWorkNeeded] = useState(null);
  const [defaultCitiesValue,setDefaultCitiesValue] = useState(null);

  const [first_appointmentDate, setFirstAppointmentDate] = useState('');
  const [second_appointmentDate, setSecondAppointmentDate] = useState('');

  const [timeslotsData,setTimeSlotsData] = useState([]);
  const [first_timeslot,set_first_timeslot] = useState('');
  const [second_timeslot,set_second_timeslot] = useState('');


  const [phone_number,setPhonenumber] = useState('');
  const [zipcode,setZipcode] = useState('');
  const [btn_loading,setbtnLoading] = useState(false)
  const [cities,setCities] = useState([])
  const [servicesData,setservicesData] = useState([])
  const [error,setError] = useState("")

  const fetchCities = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/city/get-cities`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_cities =[]
        response.data?.map(i=>{
          temp_cities.push({value:i.name,label:i.name})
        })
        temp_cities.sort((a, b) => a.value.localeCompare(b.value))

        setCities(temp_cities);
        //return temp_cities;

      } else {
       setCities([])
       //return [];
       console.log(response.status)
      }
    } catch (error) {
      console.error(error);
      setCities([])
      //return [];

    }
  };


  const fetchServices = async () => {
    try {
      const response = await axios.get(`${base_url}/apis/service/get-services`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let temp_services = []
        response.data?.map(i=>{
          temp_services.push({value:i.name,label:i.name})
        })
        temp_services.sort((a, b) => a.value.localeCompare(b.value))

        setservicesData(temp_services)
        //return temp_services;

      } else {
        
        setservicesData([])
        //return [];
      }
    } catch (error) {
      // console.error(error);
      setservicesData([])
      //return [];
     
    }
  };


  const formatTimeWithAmPm = (timeString) => {
    if (!timeString) return '';

    const [hours, minutes] = timeString.split(':');
    let formattedHours = parseInt(hours, 10);

    // Determine AM or PM
    const period = formattedHours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    formattedHours = formattedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${period}`;
  };


  const validate = ()=>{
    if(name.length<4){
      alert("Title must be 4 characters long")
      return false
    }

    if(!workNeeded){
      alert("Service Field is required")
      return false
    }

    if(city.length<1){
      alert("City Field is required")
     
      return false
    }

    if(address.length<4){
      alert("Address must be atleast 4 characters")
     
      return false
    }
    
    if(zipcode.length<4){
      alert("Zipcode must be atleast 4 characters")
     
      return false
    }

  
    if(first_appointmentDate.length<1 || second_appointmentDate.length<1){
      alert("both appointment dates are required to be selected")
      
      return false
    }

    if(first_timeslot.length<1){
      alert("Please select the 1st time slots.If incase you are unable to see the time slots then go to your account page and add time slots ")
      return false
    }


    if(second_timeslot.length<1){
      alert("Please select the 2nd time slot.If incase you are unable to see the time slot then go to your account page and add time slots ")
      return false
    }
    
    if(phone_number.length<1){
      alert("Phone number is required")
      return false
    }

    return true
  }

  const GetUserTimeSlots = async()=>{
    const user = await localStorage.getItem('handyman_ioiasod8921A_user')

    await axios.get(`${base_url}/apis/auth/profile`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      },
    })
    .then(res=>{
      if(res.status == 200){
        

        if(res.data.user.timeslot1_from.length>0 && res.data.user.timeslot1_to.length>0 && res.data.user.timeslot2_from.length>0 && res.data.user.timeslot2_to.length>0){

       
        setTimeSlotsData([
          { id: 1, from: res.data.user.timeslot1_from, to: res.data.user.timeslot1_to },
          { id: 2,from: res.data.user.timeslot2_from, to: res.data.user.timeslot2_to },
        ]);
     

        }
         

      }else{
        return false
        //return [];
      }
    })
    .catch(err=>{
      return false
      //return [];
    })
  }


  const get_job_details = async()=>{
   const job_id = await params.id
   
   const user =await localStorage.getItem('handyman_ioiasod8921A_user')

    await axios.get(`${base_url}/apis/job/view_job_for_edit?job_id=${job_id}`,{
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "authorization": user,
          'Content-Type': 'application/json', // Set the content type
        },
      })
      .then(res=>{
           
     
            setName(res.data.job.name);
            setWorkNeeded(res.data.job.type_of_work);
            setCity(res.data.job.location);

            const work = {label: res.data.job.type_of_work, value: res.data.job.type_of_work };
            const loc = {label: res.data.job.location, value: res.data.job.location };

            //const work = {label: "Handyman Services per Hour ", value: "Handyman Services per Hour " };
            //const loc = {label: "Apopka", value: "Apopka" };

            setDefaultWorkNeeded(work);
            setDefaultCitiesValue(loc);

            setFirstAppointmentDate(res.data.job.first_appointment_date);
            setSecondAppointmentDate(res.data.job.second_appointment_date);
            set_first_timeslot(res.data.job.first_timeslot);
            set_second_timeslot(res.data.job.second_timeslot);

            setZipcode(res.data.job.zipcode);
            setPhonenumber(res.data.job.phone_number);
            setAddress(res.data.job.address);

             //return res.data.job;

      })
      .catch(err=>{
        if(err && err.response){
          setError(err.response.data.message)
        }else{
          setError("Something went wrong")
        }
      })
  }


  const handleFormSubmit = async() => {
  
   const user =await localStorage.getItem('handyman_ioiasod8921A_user')
   const job_id = await params.id
    const is_validate = validate();

    if (is_validate && !btn_loading) {
     
      setbtnLoading(true);
   
      let formData = new FormData()
      formData.append('name',name)
      formData.append('type_of_work',workNeeded)
     
      formData.append('zipcode',zipcode)
      formData.append('location',city)
      formData.append('address',address)

      formData.append('phone_number',phone_number)
      formData.append('job_id',job_id)


      formData.append('first_appointment_date',first_appointmentDate)
      formData.append('second_appointment_date',second_appointmentDate)
      formData.append('first_timeslot',first_timeslot)
      formData.append('second_timeslot',second_timeslot)
      try {
        await axios.put(`${base_url}/apis/job/update_job`,formData, {
        
          
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "authorization": user,
            'Content-Type': 'application/json',
          },
        })
        .then(response=>{
          if (response.status == 200) {
            window.history.back()

            alert(response.data.message);
          } else {
            // Handle error responses here
            alert(response.data.message);
          }
        
          setbtnLoading(false);
        })
        .catch(err=>{
          alert("Something Went Wrong")
          setbtnLoading(false);

        })
      
      } catch (error) {
        alert("Something Went wrong");
        setbtnLoading(false);
      }
  
  
    }

    
  };

  useEffect(()=>{
    detectPageChange(window.location.pathname);
    
    get_job_details();

    fetchCities();
    fetchServices();
    
    GetUserTimeSlots();

 
  }, [])

  if(error) return <center className='container mt-5'>
  <h2>{error}</h2>
 </center>

return (
    <div className="container mt-5">
      <h2>Edit Job</h2>
     
        <div className="mb-3">
          
          <input
            type="text"
            className="form-control"
            placeholder='Job Title'
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

       
          <Select
            id="servicesSelect"
            options={servicesData}
            isMulti={false}
            closeMenuOnSelect={true}
            value={servicesData.filter(function(option) {
              return option.value === workNeeded;
            })}
            isLoading={servicesData.length<1?true:false}
            placeholder="Services"
            onChange={(val)=>setWorkNeeded(val.value)}
          />

        <br />
          <Select id="citiesSelect"
            options={cities}
            isMulti={false}
            closeMenuOnSelect={true}
            value={cities.filter(function(option) {
              return option.value === city;
            })}
            isLoading={cities.length<1 ?true:false}
            placeholder="City"
            onChange={(val)=>setCity(val.value)}
          />
          
        <br />
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
           Address:
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            value={address}
            placeholder='Address'
            onChange={(e) => {
              setAddress(e.target.value)
            }}
            required
          />
        </div>

        
        <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">APPOINTMENT OPTION # 1:</label>
            <input type="date" className="form-control" id="dateInput" value={first_appointmentDate} onChange={(val)=>setFirstAppointmentDate(val.target.value)}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Select Time Slot:</label>
            {timeslotsData.map((data,index)=>{
              return <div key={index} className="form-check">
              <input className="form-check-input" type="radio" name="timeSlot1"  onChange={(val)=>{set_first_timeslot(val.target.value)}} 
              value={`${formatTimeWithAmPm(data.from)} to ${formatTimeWithAmPm(data.to)}`}
              checked={`${formatTimeWithAmPm(data.from)} to ${formatTimeWithAmPm(data.to)}`== first_timeslot}
              />
              <label className="form-check-label" htmlFor="morningRadio">
                {formatTimeWithAmPm(data.from) } to {formatTimeWithAmPm(data.to)}
              </label>
            </div>
            })}
            
            <button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#myModal">
          Manage Timeslots
        </button>
          </div>
        </div>
      </div>



    </div>



    <div className="container mt-3 border p-4 rounded">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3" >
            <label htmlFor="dateInput" className="form-label">APPOINTMENT OPTION # 2:</label>
            <input type="date" className="form-control" value={second_appointmentDate} onChange={(val)=>setSecondAppointmentDate(val.target.value)} id="dateInput"/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Select Time Slot:</label>
            {timeslotsData.map((data,index)=>{
              return <div key={index} className="form-check">
              <input className="form-check-input" type="radio" name="timeSlot2"  
                  onChange={(val)=>{set_second_timeslot(val.target.value)}} 
                  value={`${formatTimeWithAmPm(data.from) } to ${formatTimeWithAmPm(data.to)}`}
                  checked={`${formatTimeWithAmPm(data.from)} to ${formatTimeWithAmPm(data.to)}` == second_timeslot}
                  />
              <label className="form-check-label" htmlFor="morningRadio">
              {formatTimeWithAmPm(data.from) } to {formatTimeWithAmPm(data.to)}

              </label>
            </div>
            })}
            
            <button type="button" className="btn btn-primary float-right" data-toggle="modal" data-target="#myModal">
          Manage Timeslots
        </button>
          </div>
        </div>
      </div>



    </div>

        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Zipcode:
          </label>
          <input
            type="text"
            className="form-control"
            id="zipCode"
            placeholder='Zipcode'
            value={zipcode}
            onChange={(e) => {
              setZipcode(e.target.value)
            }}
            required
          />
        </div>



        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Phone Number:
          </label>
          <input
            type="number"
            className="form-control"
            id="phoneNumber"
            placeholder='Phone Number'
            value={phone_number}
            onChange={(e) => {
              setPhonenumber(e.target.value)
            }}
            required
          />
        </div>


        <button onClick={handleFormSubmit} disabled={btn_loading} className="btn btn-primary">
          {btn_loading?<Spinner color='skyblue' size={18}/>:null}&nbsp;&nbsp;Submit Job
        </button>


        <button onClick={()=>window.history.back()} disabled={btn_loading} className="btn btn-danger ml-3">
          Cancel
        </button>

        <div className="modal fade" id="myModal" >
  <div className="modal-dialog" >
    <div className="modal-content">
      {/* Modal Header */}
      <div className="modal-header">
        <h4 className="modal-title">Manage Your TimeSlots</h4>
        <button type="button" className="close" data-dismiss="modal">
          ×
        </button>
      </div>
      {/* Modal body */}
     <TimeSlotManager fetchTimeSlots={GetUserTimeSlots}/>
      {/* Modal footer */}
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
    </div>
  );


};

export default EditJob;
