import {
    redirect,
    Navigate
  } from "react-router-dom";
const GuestRequire = ({children})=>{
    const user = localStorage.getItem('handyman_ioiasod8921A_user')
    if(user){
        return <Navigate to={'/home'} />
    }else{
        return children
    }
}

export default GuestRequire