import React, { useEffect, useState } from 'react';
import base_url from '../../../base_url';
import axios from 'axios';


const TimeSlotManager = ({fetchTimeSlots}) => {
  

  const [timeSlots, setTimeSlots] = useState([
    { id: 1, from: '', to: '' },
    { id: 2, from: '', to: '' },
    
  ]);

  const handleInputChange = (id, field, value) => {
    setTimeSlots((prevTimeSlots) =>
      prevTimeSlots.map((slot) =>
        slot.id === id ? { ...slot, [field]: value } : slot
      )
    );
  };

  const UpdateTimeSlots = async()=>{

    if(timeSlots[0].from.length<1 || timeSlots[0].to.length<1 || timeSlots[1].from.length<1 || timeSlots[1].to.length<1){
      alert("All the fields are required")
      return false
    }
    const user = await localStorage.getItem('handyman_ioiasod8921A_user')

    let formData = new FormData()
    formData.append('timeslot1_from',timeSlots[0].from)
    formData.append('timeslot1_to',timeSlots[0].to)

    formData.append('timeslot2_from',timeSlots[1].from)
    formData.append('timeslot2_to',timeSlots[1].to)
    try {
      const response = await fetch(base_url+'/apis/user/update/time-slots', {
        method: 'PUT',
        headers: {
          "x-api-key":process.env.REACT_APP_API_KEY,
          "authorization": user
        },
        body: formData,
      });

      if (response.ok) {
        const data =await response.json()

          localStorage.setItem('handyman_ioiasod8921A_user',data.token)
          alert("Your Time Slots Updated Successfully")
          fetchTimeSlots()
      } else {
        alert("Something Went Wrong")
      }
    } catch (error) {
      alert("Something Went Wrong")

    }
    
  }

  const GetUserTimeSlots = async()=>{
    const user = await localStorage.getItem('handyman_ioiasod8921A_user')

    await axios.get(`${base_url}/apis/auth/profile`,{
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "authorization": user,
        'Content-Type': 'application/json', 
      },
    })
    .then(res=>{
      if(res.status == 200){
       
        let temp_timeslots = [
          { id: 1, from: res.data.user.timeslot1_from, to: res.data.user.timeslot1_to },
          { id: 2, from: res.data.user.timeslot2_from, to: res.data.user.timeslot2_to },
        ]

        setTimeSlots(temp_timeslots)
      }else{
        return false
      }
    })
    .catch(err=>{
     return false
    })
  }

  useEffect(()=>{
    GetUserTimeSlots()
  },[])

  return (
    <div className="container mt-5">
   
      {timeSlots.map((slot) => (
        <div key={slot.id} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">Time Slot {slot.id}</h5>
            <div className="form-group">
              <label htmlFor={`startTime${slot.id}`}>From:</label>
              <input
                type="time"
                className="form-control"
                id={`startTime${slot.id}`}
                value={slot.from}
                onChange={(e) => handleInputChange(slot.id, 'from', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`endTime${slot.id}`}>To:</label>
              <input
                type="time"
                className="form-control"
                id={`endTime${slot.id}`}
                value={slot.to}
                onChange={(e) => handleInputChange(slot.id, 'to', e.target.value)}
              />
            </div>
          </div>
        </div>
      ))}


      <button className='btn btn-primary mb-4' onClick={UpdateTimeSlots} style={{width:'100%'}}>Update</button>
     

    </div>
  );
};

export default TimeSlotManager;
