import React, { useEffect, useState } from "react";
import base_url from "../../base_url";
import Select from 'react-select';
import axios from "axios";
import { Spinner } from "react-activity";
export default function OfferContainer({message,decodedTokenRef,acceptingOfferLoading,accept_offer,services,socket,selectedChat,payment_accounts}){
    const [workNeeded, setWorkNeeded] = useState(message.work_need_done);
    const [downPayment,setDownPayment] = useState(message?.down_payment)
    const [finalPayment,setFinalPayment] = useState(message?.final_payment) 
    const [offer_message,setMessage] = useState(message?.message)
   
    const [accept_offer_loading,setAcceptOfferLoading] = useState(false)
   

    const pay_for_offer =  () => {
      if (payment_accounts.length < 1) {
        alert("Handyman has not added a payment method. Please let the handyman know to add a payment method.");
        return false;
      }
    
      setAcceptOfferLoading(true);
    
     
        const user = localStorage.getItem('handyman_ioiasod8921A_user');
        const apiUrl = `${base_url}/apis/order/accept_offer_payment_creation`;
    
          axios.post(
          apiUrl,
          {
            offer_id: message._id,
            payment_method_id: payment_accounts[0]._id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_API_KEY,
              authorization: user
            }
          }
        )
        .then((response)=>{
          console.log(response)
          window.location = response.data.link;

        })
        .catch(err=>{
         
          setAcceptOfferLoading(false);
          
          alert("Something went wrong. Please try again later.");

        })
      }
    
      
    

    const fetchOfferDetails = async(offer_id)=>{
      const user = await localStorage.getItem('handyman_ioiasod8921A_user')

       axios.get(`${base_url}/apis/message/offer-details?offer_id=${offer_id}`,{
           headers: {
             "x-api-key": process.env.REACT_APP_API_KEY,
             "authorization": user,
             'Content-Type': 'application/json', 
           }})
       .then(res=>{
          
           setDownPayment(res.data.down_payment)
           setFinalPayment(res.data.final_payment)
           setMessage(res.data.message)
           setWorkNeeded(res.data.work_need_done)
       })
       .catch(err=>{
           alert(err.message)
       })
     }



    useEffect(()=>{
      socket.on('offer_updated',(data)=>{
        if(data.offer_id == message._id){
          fetchOfferDetails(data.offer_id)
        }
      })
    },[socket])


    
    return(
        <>

        <div className="offer_message mt-2" >
        <div className="d-flex align-items-center border" style={{backgroundColor:'#007aff',padding:5,borderRadius:5}}>
          
          
          <a href={`/contractor_profile/${message.sender_user[0]?._id}`}><strong style={{ color: 'white' }}>
            <img
              src={message.sender_user[0]?.profile_picture?message.sender_user[0]?.profile_picture:require('../../assets/user-circle.png')}
              style={{ width: 30, height: 30, borderRadius: '50%', marginRight: '10px' }}
              alt="User Profile"
            />
            {message.sender_user[0]?._id == decodedTokenRef.current._id? 'Me':message.sender_user[0]?.username}
          </strong>
          </a>
          <span className="ml-auto small" style={{color:'white'}}>
            {new Date(message.createdAt).toLocaleString()}
          </span>
        </div>

       {decodedTokenRef.current?.user_type === "contractor" && message.is_accepted == false?<button onClick={()=>window.location=`/edit_offer/${message._id}`} className='btn btn-primary float-right' >Edit</button>:null}
          <br />
          <h4>{message.job[0]? message.job[0].name:message.job_title}</h4>
          <b style={{ color: 'black' }}>Handyman Offer: </b>
          <br />
          <span className="float-left">{offer_message}</span>
          <br />
          <span><b style={{ color: 'black' }}>Down Payment:</b> ${downPayment}</span>
          <br />
          <span><b style={{ color: 'black' }}>Final Payment:</b> ${finalPayment}</span>

          <br />
          <b style={{ color: 'black' }}>Services: </b><p>{workNeeded}</p>
          {decodedTokenRef.current != null && decodedTokenRef.current?.user_type === "client" ? (
            <center>

          
              <button disabled={message.is_accepted || acceptingOfferLoading} onClick={pay_for_offer}  className='btn btn-success' style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>{acceptingOfferLoading?<Spinner color="skyblue" size={25}/>:null}{accept_offer_loading?<Spinner color="skyblue" size={20}/>:null}{message.is_accepted === false ? 'Accept offer' : 'Accepted'}</button>

              {message.is_accepted == false?<small style={{color:'gray'}}>Offer will expire in 3 days</small>:null}
            </center>
          ) : (
            <div style={{ width: '100%', marginTop: 20, marginBottom: 20, borderRadius: 5, padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: '#D3D3D3' }} className='border'>
              {message.is_accepted === false ? 'Waiting For Response' : 'Accepted'}
            </div>
          )}




        </div>
          <br />


        
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <hr style={{ width: '80%' }} />
            <div style={{ position: 'relative', top: '-30px', backgroundColor: 'white', padding: '0 10px',fontWeight:'bold' }}>{message.job[0]? message.job[0].name:message.job_title}</div>
          </div>

        </>

    )
}